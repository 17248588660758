<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col">
          <h6>REGISTRAR ABOGADOS EN EL SISTEMA</h6>
        </div>
      </div>
    </div>
    <div class="card-body px-4">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Nombre</label>
          <input type="text" class="form-control" v-model="lawyer.name">
          <label>Email</label>
          <input type="text" class="form-control" v-model="lawyer.email">
          <label>Phone</label>
          <input type="text" class="form-control" v-model="lawyer.phone">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label>Descripcion</label>
          <textarea type="text" class="form-control" v-model="lawyer.description" rows="5"></textarea>
          <button class="btn bg-gradient-success float-end w-100 mt-2" @click="createLawyer">CREAR NUEVO ABOGADO</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {post} from "@/utils/httpRequest";

export default {
  data: () => ({
    lawyer:{
      name:'',
      description:'',
    }
  }),
  methods:{
    async createLawyer() {
      if (this.lawyer.name !== '' && this.lawyer.description !== '') {
        await post('lawyer/create', this.lawyer)
        this.lawyer = {
          name:'',
          description:'',
        }
        await Swal.fire('Abogado creado correctamente', '', 'success')
      } else {
        await Swal.fire('Todos los campos son requeridos', '', 'error')
      }
    }
  }
}
</script>
<style scoped>

</style>
